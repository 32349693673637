export default function formatTime(minutes: number): string {
  if (minutes < 0) {
    throw new Error("O tempo não pode ser negativo");
  }

  if (minutes < 60) {
    return `${minutes}min`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (remainingMinutes === 0) {
    return `${hours}h`;
  }

  return `${hours}h${remainingMinutes}min`;
}

// Exemplos de uso:
// console.log(formatTime(30));     // "30min"
// console.log(formatTime(60));     // "1h"
// console.log(formatTime(90));     // "1h30min"
// console.log(formatTime(120));    // "2h"
// console.log(formatTime(150));    // "2h30min"
// console.log(formatTime(1440));   // "24h"
