export const typesTraductions = {
  all: "Todos",
  automatic: "Automático",
  delivering: "Em entrega",
  manual: "Manual",
  pending: "Pendente",
  production: "Em produção",
  done: "Finalizado",
  canceled: "Cancelado",
  delivery: "Entrega",
  pickup: "Retirada",
  "dine-in": "Consumo no local",
};
