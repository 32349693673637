import { z } from "zod";

export const checkoutSchema = z.object({
  delivery_method: z.enum(["delivery", "pickup", "dine-in"]),
  paymentMethod: z
    .object({
      id: z.string(),
      name: z.string(),
      flag: z.string(),
    })
    .refine((data) => !!data.id, {
      message: "Selecione um método de pagamento",
    }),
  address: z
    .object({
      street: z.string(),
      number: z.coerce.number(),
      district: z.string(),
      city: z.string(),
      state: z.string(),
      postal_code: z.string(),
      info: z.string().optional(),
    })
    .optional(),
  description: z.string().optional(),
  changeAmount: z.number().optional(),
  deliveryDistance: z.number().optional(),
});

export type CheckoutSchema = z.infer<typeof checkoutSchema>;
