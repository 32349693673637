import { ReactNode, useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "./components/header";
import CartStep from "./steps/cart.step";
import AddressStep from "./steps/address.step";
import NextStepButton from "./components/next-step-button";
import { CheckoutSchema, checkoutSchema } from "./schemas/checkout.schemas";
import PaymentStep from "./steps/payment.step";
import SummaryStep from "./steps/summary.step";

export type CheckoutStep = "cart" | "address" | "payment" | "summary";
export default function CartCheckout({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<CheckoutStep>("cart");

  const form = useForm<CheckoutSchema>({
    resolver: zodResolver(checkoutSchema),
    mode: "onChange",
    defaultValues: {
      delivery_method: "delivery",
    },
  });

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>{children}</SheetTrigger>
      <SheetContent
        className="w-full max-w-[unset] p-0 overflow-auto flex flex-col gap-0 flex-1 h-full"
        showCloseButton={false}
        side="bottom"
      >
        <Header
          setOpen={setOpen}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        <CartStep setOpen={setOpen} currentStep={currentStep} />
        <AddressStep currentStep={currentStep} form={form} />
        <PaymentStep currentStep={currentStep} form={form} />
        <SummaryStep currentStep={currentStep} form={form} />
        <NextStepButton
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          form={form}
          setOpen={setOpen}
        />
      </SheetContent>
    </Sheet>
  );
}
