import api from "@/config/api.config";
import { Business } from "@/types/business.type";
import { Order, OrderUserAddress } from "@/types/order.type";
import { ShoppingCartItem } from "@/types/shopping-cart.type";
import { User } from "@/types/user.type";

export type PostOrderProps = {
  user: Pick<User, "id" | "name" | "phone_number">;
  address?: OrderUserAddress;
  items: ShoppingCartItem[];
  payment_method: {
    name: string;
    flag: string;
  };
  delivery_method: Order["delivery_method"];
  description?: string;
  change_amount?: number | null;
  delivery_fee?: number;
  business: Business;
};

export async function postOrder({
  description,
  items,
  address,
  payment_method,
  user,
  business,
  change_amount,
  delivery_fee,
  delivery_method,
}: PostOrderProps) {
  const body = {
    type: "automatic",
    business_id: business.id,
    user_id: user.id,
    description,
    status: "pending",
    delivery_method,
    metadata: {
      items,
      user: {
        id: user.id,
        name: user.name,
        phone_number: user.phone_number,
        address: delivery_method === "delivery" ? address : undefined,
      },
      business: {
        id: business.id,
        name: business.name,
        phone_number: business.phone_number,
        logo_url: business.logo_url,
        banner_url: business.banner_url,
      },
      payment_method,
      change_amount,
      delivery_fee,
    },
  };

  const { data: order } = await api.post("/order", body);

  return order as Order;
}
