import { useEffect, useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Controller, UseFormReturn } from "react-hook-form";
import { SelectAddressSheet } from "@/components/selectAddress.sheet";
import { useUserStore } from "@/pages/profile/store/user.store";
import {
  IconAlertCircle,
  IconBike,
  IconBuildingStore,
  IconWalk,
} from "@tabler/icons-react";
import AuthenticationDrawer from "@/components/authentication/authentication.drawer";
import { useAuth } from "@/context/auth.context";
import { CheckoutStep } from "../cart";
import { CheckoutSchema } from "../schemas/checkout.schemas";
import { cn } from "@/lib/utils";
import { useBusinessStore } from "@/pages/business/store/business.store";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { getGoogleMapsAddressesDistance } from "@/pages/address/queries/address.queries";
import { formatUserAddress } from "@/utils/formatUserAddress.util";

export default function AddressStep({
  currentStep,
  form,
}: {
  currentStep: CheckoutStep;
  form: UseFormReturn<CheckoutSchema, unknown, undefined>;
}) {
  const { signed } = useAuth();
  const [authOpen, setAuthOpen] = useState(false);

  const business = useBusinessStore((state) => state.business);
  const delivery_method = form.watch("delivery_method");

  useEffect(() => {
    if (signed) return setAuthOpen(false);
    if (currentStep === "address") setAuthOpen(true);
  }, [currentStep, signed]);

  useEffect(() => {
    if (delivery_method !== "delivery") {
      form.setValue("deliveryDistance", 0);
      form.setValue("address", undefined);
    }
  }, [delivery_method, form]);

  if (currentStep !== "address") return null;

  const hasNoAddresOption =
    !business?.metadata.deliveryMethods?.delivery.enabled &&
    !business?.metadata.deliveryMethods?.dineIn.enabled &&
    !business?.metadata.deliveryMethods?.pickup.enabled;

  return (
    <div className="flex gap-6 flex-col flex-1 h-full p-4">
      <h3 className="font-semibold text-base">Opções de entrega</h3>

      {hasNoAddresOption && (
        <Alert variant="destructive">
          <IconAlertCircle className="h-4 w-4" />
          <AlertDescription>
            Infelizmente não há opções de entrega disponíveis para este
            estabelecimento.
          </AlertDescription>
        </Alert>
      )}

      <Controller
        name="delivery_method"
        control={form.control}
        render={({ field, fieldState }) => (
          <div className="flex flex-col gap-2">
            <RadioGroup
              value={field.value}
              onValueChange={field.onChange}
              className="flex flex-col gap-3"
            >
              {delivery_method === "delivery" &&
                form.formState.errors.deliveryDistance?.message ===
                  "max_distance exceeded" && (
                  <Alert variant="destructive" className="animate-shake">
                    <IconAlertCircle className="h-4 w-4" />
                    <AlertDescription>
                      Infelizmente o endereço de entrega selecionado não é
                      atendido pela loja.
                    </AlertDescription>
                  </Alert>
                )}
              {business?.metadata.deliveryMethods?.delivery.enabled && (
                <DeliveryOption
                  isSelected={field.value === "delivery"}
                  onSelect={field.onChange}
                  hasError={
                    !!fieldState.error ||
                    !!form.formState.errors.deliveryDistance
                  }
                  form={form}
                />
              )}
              {business?.metadata.deliveryMethods?.dineIn.enabled && (
                <DineInOption
                  isSelected={field.value === "dine-in"}
                  onSelect={field.onChange}
                />
              )}
              {business?.metadata.deliveryMethods?.pickup.enabled && (
                <PickupOption
                  isSelected={field.value === "pickup"}
                  onSelect={field.onChange}
                />
              )}
            </RadioGroup>
          </div>
        )}
      />

      <AuthenticationDrawer open={authOpen} setOpen={setAuthOpen} />
    </div>
  );
}

const DeliveryOption = ({
  isSelected,
  form,
  onSelect,
  hasError,
}: {
  isSelected: boolean;
  onSelect: (value: string) => void;
  hasError: boolean;
  form: UseFormReturn<CheckoutSchema>;
}) => {
  const [authOpen, setAuthOpen] = useState(false);
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);

  const { signed } = useAuth();

  const business = useBusinessStore((state) => state.business);

  const user = useUserStore((state) => state.user);
  const userAddresses = user?.addresses || [];
  const userMainAddress = userAddresses.find((address) => address.is_selected);

  async function handleCalculateDelivery() {
    if (!userMainAddress) return;
    if (!business?.address) return;

    const distance = await getGoogleMapsAddressesDistance(
      business.address,
      formatUserAddress(userMainAddress)
    );

    if (
      business.metadata.deliveryMethods?.delivery?.max_distance &&
      distance / 1000 > business.metadata.deliveryMethods.delivery.max_distance
    ) {
      console.log("max_distance exceeded");
      form.setError("deliveryDistance", {
        type: "manual",
        message: "max_distance exceeded",
      });
    } else {
      form.clearErrors("deliveryDistance");
      form.setValue("deliveryDistance", distance);
    }
  }

  useEffect(() => {
    if (!userMainAddress) return;

    handleCalculateDelivery();

    form.setValue("address", userMainAddress);
    form.trigger();
  }, [userMainAddress, form]);

  return (
    <Card
      onClick={() => {
        onSelect("delivery");
      }}
      className={cn(
        "border-2 w-full text-start transition-colors cursor-pointer",
        {
          "border-primary": isSelected && !hasError,
          "border-destructive": hasError && isSelected,
          "border-input": !isSelected,
        }
      )}
    >
      <CardContent className="flex items-center gap-3 p-4">
        <RadioGroupItem value="delivery" className="sr-only" id="delivery" />
        <IconBike
          size={24}
          className={cn("shrink-0", {
            "text-primary": isSelected && !hasError,
            "text-destructive": hasError && isSelected,
            "text-muted-foreground": !isSelected,
          })}
          aria-hidden="true"
        />
        <Label htmlFor="delivery" className="flex-1 cursor-pointer select-none">
          {userMainAddress && (
            <div className="flex items-start justify-between w-full">
              <div className={"flex flex-col gap-1"}>
                <p
                  className={cn("text-label-primary font-semibold", {
                    "text-destructive": hasError && isSelected,
                  })}
                >
                  {userMainAddress.street}, {userMainAddress.number}
                </p>
                <p
                  className={cn("text-label-secondary", {
                    "text-destructive": hasError && isSelected,
                  })}
                >
                  {userMainAddress.district}, {userMainAddress.city} -{" "}
                  {userMainAddress.state}
                </p>
              </div>
            </div>
          )}
          {!userMainAddress && (
            <p
              className={cn("text-label-primary", {
                "font-semibold": isSelected && !hasError,
                "text-destructive": hasError && isSelected,
              })}
            >
              Receber no seu endereço
            </p>
          )}
        </Label>
        <SelectAddressSheet
          open={selectAddressOpen}
          setOpen={setSelectAddressOpen}
        >
          <button
            type="button"
            className={cn("flex items-center justify-center gap-2 underline", {
              "text-primary": isSelected && !hasError,
              "text-destructive": hasError && isSelected,
            })}
            onClick={(e) => {
              e.preventDefault();

              if (!signed) return setAuthOpen(true);

              setSelectAddressOpen(true);
            }}
          >
            {userMainAddress ? "Alterar" : "Selecionar"}
          </button>
        </SelectAddressSheet>

        <AuthenticationDrawer open={authOpen} setOpen={setAuthOpen} />
      </CardContent>
    </Card>
  );
};

const DineInOption = ({
  isSelected,
  onSelect,
}: {
  isSelected: boolean;
  onSelect: (value: string) => void;
}) => {
  const business = useBusinessStore((state) => state.business);

  return (
    <Card
      className={cn(
        "border-2 w-full text-start transition-colors cursor-pointer",
        {
          "border-primary": isSelected,
          "border-input": !isSelected,
        }
      )}
      onClick={() => onSelect("dine-in")}
    >
      <CardContent className="flex items-center gap-3 p-4">
        <RadioGroupItem value="dine-in" className="sr-only" id="dine-in" />
        <IconBuildingStore
          size={24}
          className={cn("shrink-0", {
            "text-primary": isSelected,
            "text-muted-foreground": !isSelected,
          })}
          aria-hidden="true"
        />
        <div className="flex flex-col gap-1">
          <Label
            htmlFor="dine-in"
            className="flex-1 cursor-pointer select-none"
          >
            <p>Consumir no local</p>
          </Label>
          {isSelected && (
            <p className="text-label-secondary text-sm font-medium">
              {business?.address}
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const PickupOption = ({
  isSelected,
  onSelect,
}: {
  isSelected: boolean;
  onSelect: (value: string) => void;
}) => {
  const business = useBusinessStore((state) => state.business);

  return (
    <Card
      onClick={() => onSelect("pickup")}
      className={cn(
        "border-2 w-full text-start transition-colors cursor-pointer",
        {
          "border-primary": isSelected,
          "border-input": !isSelected,
        }
      )}
    >
      <CardContent className="flex items-center gap-3 p-4">
        <RadioGroupItem value="pickup" className="sr-only" id="pickup" />
        <IconWalk
          size={24}
          className={cn("shrink-0", {
            "text-primary": isSelected,
            "text-muted-foreground": !isSelected,
          })}
          aria-hidden="true"
        />
        <div className="flex flex-col gap-1">
          <Label htmlFor="pickup" className="flex-1 cursor-pointer select-none">
            <p>Retirar no estabelecimento</p>
          </Label>
          {isSelected && (
            <p className="text-label-secondary text-sm font-medium">
              {business?.address}
            </p>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
