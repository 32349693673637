import { Business } from "@/types/business.type";
import isBusinessInOperationTime from "@/utils/isBusinessInOperationTime.util";
import BusinessSheet from "./business-sheet/business.sheet";
import { cn } from "@/lib/utils";
import { IconCash, IconClock, IconMapPin } from "@tabler/icons-react";
import isBusinessOpenUntil from "@/utils/isBusinessOpenUntil.util";
import BusinessLogo from "@/components/fallbacks/logos/business-logo";
import BusinessBanner from "@/components/fallbacks/logos/business-banner";
import formatMoneyValue from "@/utils/formatMoneyValue.util";
import formatTime from "@/utils/formatTime.util";

type BusinessHeaderProps = {
  business: Business;
};
export default function BusinessHeader({ business }: BusinessHeaderProps) {
  const isBusinessOpenNow = business
    ? isBusinessInOperationTime(business)
    : false;

  return (
    <>
      <div className="relative">
        <div className="h-44 lg:h-72 w-full overflow-hidden">
          <BusinessBanner
            logo_url={business.banner_url}
            className="object-cover h-full w-full"
          />
        </div>
        <div className="absolute -bottom-6 left-4 z-10">
          <div className="rounded-full border-4 border-white overflow-hidden z-10">
            <BusinessLogo
              logo_url={business.logo_url}
              className="object-cover w-20 h-20"
            />
          </div>
        </div>
      </div>

      <div className="px-4 pt-8 pb-4">
        <div className="flex items-center justify-between">
          <h1 className="flex-1 text-xl font-semibold text-label-primary">
            {business.name}
          </h1>
          <BusinessSheet business={business} />
        </div>
        <div className="flex flex-col">
          {business.metadata.deliveryMethods?.estimated_delivery_time ? (
            <div className="flex items-center gap-2 mt-2 text-label-secondary">
              <IconClock size={16} className="min-h-4 min-w-4" />
              <span>
                Tempo de entrega de{" "}
                <span>
                  {formatTime(
                    business.metadata.deliveryMethods?.estimated_delivery_time
                  )}
                </span>
              </span>
            </div>
          ) : null}
          {business.metadata.deliveryMethods?.min_order_value ? (
            <div className="flex items-center gap-2 mt-2 text-label-secondary">
              <IconCash size={16} className="min-h-4 min-w-4" />
              <span>
                Pédido mínimo de{" "}
                <span>
                  {formatMoneyValue(
                    business.metadata.deliveryMethods?.min_order_value
                  )}
                </span>
              </span>
            </div>
          ) : null}
          <div className="flex items-center gap-2 mt-2 text-label-secondary">
            <IconMapPin size={16} className="min-h-4 min-w-4" />
            <p>{business.address}</p>
          </div>
          <div
            className={cn("flex items-center gap-2 mt-2 ", {
              "text-red-500": !isBusinessOpenNow,
              "text-green-600": isBusinessOpenNow,
            })}
          >
            <IconClock className="h-4 w-4" />
            <span>{isBusinessOpenUntil(business)}</span>
          </div>
        </div>
      </div>
    </>
  );
}
