/* eslint-disable @typescript-eslint/no-explicit-any */
import api from "@/config/api.config";
import { AddressForm } from "../schemas/address.schemas";
import { UserAddress } from "@/types/user.type";
import globalApi from "@/config/globalApi.config";

export async function postUserAddress(data: AddressForm): Promise<UserAddress> {
  try {
    const response = await api.post(`user/${data.user_id}/address`, data);

    return response.data;
  } catch (e) {
    console.error(e);

    throw new Error("Error creating address");
  }
}

export async function patchUserAddress(
  userId: number,
  addressId: number,
  data: Partial<UserAddress>
): Promise<UserAddress> {
  try {
    const response = await api.patch(
      `user/${userId}/address/${addressId}`,
      data
    );

    return response.data;
  } catch (e) {
    console.error(e);

    throw new Error("Error updating address");
  }
}

export async function deleteUserAddress(
  userId: number,
  addressId: number
): Promise<void> {
  try {
    await api.delete(`user/${userId}/address/${addressId}`);
  } catch (e) {
    console.error(e);

    throw new Error("Error deleting address");
  }
}

// Todo: descobrir o tipo de return aqui
export async function getGoogleMapsAddress(placeId: string): Promise<any> {
  try {
    const response = await globalApi.get(`/google-maps/address/${placeId}`);

    return response.data;
  } catch (e) {
    console.error(e);

    throw new Error("Error fetching address");
  }
}

// Todo: descobrir o tipo de return aqui
export async function getGoogleMapsSearch(search: string): Promise<any> {
  try {
    const response = await globalApi.post(`/google-maps`, {
      search,
    });

    return response.data;
  } catch (e) {
    console.error(e);

    throw new Error("Error fetching address");
  }
}

export async function getGoogleMapsAddressesDistance(
  origin: string,
  destination: string
): Promise<number> {
  try {
    const response = await globalApi.post(`/google-maps/distance`, {
      origin,
      destination,
    });

    return response.data.distance_meters;
  } catch (e) {
    console.error(e);

    throw new Error("Error fetching address");
  }
}
